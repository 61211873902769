$(document).ready(function() {

    /*
        MODULES
     */
    $('#main .mod-slider .owl-carousel').owlCarousel({
        items: 1,
        loop: false,
        nav: true,
        navText: ['<img src="/wp-content/themes/tiz-adam/assets/svg/layout/chevron-white-left.svg" />', '<img src="/wp-content/themes/tiz-adam/assets/svg/layout/chevron-white-right.svg" />'],
        dots: true,
        autoplay:true,
        autoplayTimeout: 3000,
        autoplayHoverPause:true
    });

    $('#main .mod-slider .slide').on('click', function() {
        var dest = $(this).data('src');

        if (dest == '#popup-coming-soon') {
            return true;
        } else {
            document.location.href = dest;
        }

       return false;
    });

    $('#main .mod-products-list .owl-carousel').owlCarousel({
        items: 1,
        loop: false,
        nav: false,
        dots: true,
        margin: 30,
        responsive:{
            480:{
                items:1
            },
            767:{
                items:2
            },
            991:{
                items:3
            },
            1159:{
                items:4,
            },
        }
    });

    $('#main .mod-savoir-faire .owl-carousel').owlCarousel({
        items: 1,
        loop: false,
        nav: false,
        dots: true,
        margin: 30,
        responsive:{
            480:{
                items:1
            },
            767:{
                items:2
            },
            991:{
                items:3
            }
        }
    });

    $('#main .mod-savoir-faire .block').on('click', function() {
        var dest = $(this).data('href');

        document.location.href = dest;

        return false;
    });

    $('#main #list-articles').owlCarousel({
        items: 1,
        loop: false,
        nav: false,
        dots: true,
        margin: 30,
        responsive:{
            480:{
                items:1
            },
            767:{
                items:2
            },
            991:{
                items:3
            }
        }
    });
});
